<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <v-select
      :id="id"
      v-model="formValue"
      :placeholder="placeholder"
      :options="options"
      :clearable="clearable"
      :disabled="disabled"
      :state="state"
      label="text"
      :reduce="option => option.value"
    >
      <template #option="option">
        <span :class="`text-${dotStatusType(option.campaign_status_id)}`">●</span>
        {{ option.text }}
      </template>
    </v-select>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Constant from '@/utils/Constant'

export default {
  components: {
    BFormGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    // Got value from v-model
    value: {
      type: [String],
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
      default: new Array([]),
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    column: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: null,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value !== null) {
          this.$emit('input', value)
        } else {
          this.$emit('input', '')
        }
      },
    },
  },
  methods: {
    dotStatusType(campaignStatusId) {
      const { campaignDotStatuses } = Constant

      const currentDotStatus = campaignDotStatuses.find(campaignDotStatus => campaignDotStatus.id === campaignStatusId)
      return currentDotStatus ? currentDotStatus.type : 'danger'
    },
  },
}
</script>
