<template>
  <b-tab lazy title="Syncing Status" class="mt-2">
    <!-- Campaign -->
    <b-row>
      <b-col cols="12" sm="12" lg="6" xl="3">
        <HeroVisionCampaignSelect
          id="campaign-id"
          v-model="campaignId"
          label="Campaign"
          placeholder="Select Campaign"
          :clearable="false"
          :options="campaignOptions"
        />
      </b-col>

      <b-col cols="12" sm="12" lg="1" xl="1" style="padding-top: 24px;">
        <HeroButtonAction
          type="button"
          variant="primary"
          @click="checkCampaignSyncingStatus"
        >
          Check
        </HeroButtonAction>
      </b-col>
    </b-row>

    <!-- Channel -->
    <b-row>
      <b-col cols="12" sm="12" lg="6" xl="3">
        <HeroVueSelect
          id="channel-type"
          v-model="channelType"
          label="Channel Type"
          placeholder="Choose Channel Type"
          :clearable="false"
          :options="channelTypeOptions"
        />
      </b-col>
      <b-col cols="12" sm="12" lg="6" xl="3">
        <HeroInputText
          id="channel-id"
          v-model="channelId"
          :label="channelIdLabel"
          :clearable="true"
        />
      </b-col>
      <b-col cols="12" sm="12" lg="1" xl="1" style="padding-top: 24px;">
        <HeroButtonAction
          type="button"
          variant="primary"
          @click="checkChannelSyncingStatus"
        >
          Check
        </HeroButtonAction>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row>
      <b-col>
        <b-table-simple v-if="failResults.length > 0" responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
          <b-thead>
            <b-tr>
              <b-th class="smaller fail-header">
                Status
              </b-th>
              <b-th class="smaller fail-header">
                Campaign Name
              </b-th>
              <b-th class="smaller fail-header">
                Cycle
              </b-th>
              <b-th class="smaller fail-header">
                Product
              </b-th>
              <b-th class="smaller fail-header">
                Analytic Campaign Id
              </b-th>
              <b-th class="smaller fail-header">
                Analytic Campaign Name
              </b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <template v-for="result in failResults">
              <b-tr :key="result.id">
                <!-- Status -->
                <b-td>
                  <template>
                    <feather-icon
                      icon="FrownIcon"
                      class="mr-50"
                    />
                    <span>Fail</span>
                  </template>
                </b-td>

                <b-td style="text-align: left;">
                  {{ result.campaign_name }}
                </b-td>
                <b-td style="text-align: left;">
                  {{ result.cycle_number }}
                </b-td>

                <b-td style="text-align: left;">
                  <template v-if="result.product_comment">
                    {{ result.campaign_product_name }} [ {{ result.product_comment }} ]
                  </template>
                  <template v-if="!result.product_comment">
                    {{ result.campaign_product_name }}
                  </template>
                </b-td>

                <b-td style="text-align: left;">
                  {{ result.analytic_campaign_id }}
                </b-td>
                <b-td style="text-align: left;">
                  {{ result.analytic_campaign_name }}
                </b-td>
              </b-tr>
              <b-tr :key="result.id">
                <b-td colspan="6" style="text-align: left;">
                  {{ result.message }}
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>

        <b-table-simple v-if="successResults.length > 0" responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
          <b-thead>
            <b-tr>
              <b-th class="smaller success-header">
                Status
              </b-th>
              <b-th class="smaller success-header">
                Campaign Name
              </b-th>
              <b-th class="smaller success-header">
                Cycle
              </b-th>
              <b-th class="smaller success-header">
                Product
              </b-th>
              <b-th class="smaller success-header">
                Analytic Campaign Id
              </b-th>
              <b-th class="smaller success-header">
                Analytic Campaign Name
              </b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <template v-for="result in successResults">
              <b-tr :key="result.id">
                <!-- Status -->
                <b-td>
                  <template>
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-50"
                    />
                    <span>Success</span>
                  </template>
                </b-td>

                <b-td style="text-align: left;">
                  <router-link :to="`/campaigns/${result.campaign_id}`">
                    {{ result.campaign_name }}
                  </router-link>
                </b-td>
                <b-td style="text-align: left;">
                  {{ result.cycle_number }}
                </b-td>

                <b-td style="text-align: left;">
                  <template v-if="result.product_comment">
                    {{ result.campaign_product_name }} [ {{ result.product_comment }} ]
                  </template>
                  <template v-if="!result.product_comment">
                    {{ result.campaign_product_name }}
                  </template>
                </b-td>

                <b-td style="text-align: left;">
                  {{ result.analytic_campaign_id }}
                </b-td>
                <b-td style="text-align: left;">
                  {{ result.analytic_campaign_name }}
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroVisionCampaignSelect from '@/views/components/form/selects/HeroVisionCampaignSelect.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'

export default {
  components: {
    BRow,
    BCol,
    HeroVisionCampaignSelect,
    HeroVueSelect,
    HeroInputText,
    HeroButtonAction,
  },
  data() {
    return {
      campaignId: '',
      channelId: '',
      channelType: 'submitted',
      channelIdLabel: 'Analytic Campaign Id',

      campaignOptions: [],
      channelTypeOptions: [
        { text: 'Submitted', value: 'submitted' },
        { text: 'Direct', value: 'direct' },
        { text: 'Phone', value: 'phone' },
        { text: 'ACI Chatbot', value: 'aci-chatbot' },
        { text: 'Direct Chatbot', value: 'direct-chatbot' },
      ],

      failResults: [],
      successResults: [],
    }
  },
  watch: {
    channelType(newChannelType) {
      if (newChannelType === 'submitted' || newChannelType === 'aci-chatbot') {
        this.channelIdLabel = 'Analytic Campaign Id'
      }

      if (newChannelType === 'direct' || newChannelType === 'direct-chatbot') {
        this.channelIdLabel = 'Direct Channel Id'
      }

      if (newChannelType === 'phone') {
        this.channelIdLabel = 'DID Phone'
      }
    },
  },
  async mounted() {
    await this.getCampaignOptions()
  },
  methods: {
    // โหลดข้อมูลแคมเปญ
    async getCampaignOptions() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const response = await axios.get('dropdown-options/herovision-campaign-options?orderByColumn=name&orderByDirection=asc', axiosConfig)

        if (response.data.data) {
          this.campaignOptions = response.data.data.map(campaign => ({
            text: campaign.name,
            value: campaign.id,
            campaign_status_id: campaign.campaign_status_id,
          }))
          if (this.campaignOptions.length >= 1) {
            this.campaignId = this.campaignOptions[0].value
          }
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async checkCampaignSyncingStatus() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const payload = {
          lead_campaign_id: this.campaignId,
        }

        const response = await axios.post('lead-service/support/syncing-status', payload, axiosConfig)

        if (response.data.status === 'success') {
          const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
          this.$toast(toastObject)

          this.failResults    = response.data.fail_result
          this.successResults = response.data.success_result
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    // eslint-disable-next-line consistent-return
    async checkChannelSyncingStatus() {
      if (!this.channelId) {
        const toastObject = Toastification.getContentError(`Please enter a ${this.channelIdLabel}.`)
        this.$toast(toastObject)

        return false
      }

      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const payload = {
          lead_channel_id: this.channelId,
          lead_channel_type: this.channelType,
        }

        const response = await axios.post('lead-service/support/syncing-status', payload, axiosConfig)

        if (response.data.status === 'success') {
          const toastObject = Toastification.getContentSuccess(`${response.data.message}`)
          this.$toast(toastObject)

          this.failResults    = response.data.fail_result
          this.successResults = response.data.success_result
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },
  },
}
</script>

<style scoped>
.smaller {
  font-size: smaller;
}

.fail-header {
  background-color: #E74C3C !important;
  color: white;
}

.success-header {
  background-color: #2ECC71 !important;
  color: white;
}
</style>
